<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine whether each of the descriptions below are a better description of potential or
        kinetic energy.
      </p>
      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Description</th>
            <th style="font-size: 15px">Potential</th>
            <th style="font-size: 15px">Kinetic</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>a) The water in a river naturally flowing</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="potential" value="potential" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="kinetic" value="kinetic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>b) River water being held back by a dam</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="potential" value="potential" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="kinetic" value="kinetic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>c) A battery</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="potential" value="potential" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="kinetic" value="kinetic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>d) Fuel sitting in a gas can</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="potential" value="potential" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="kinetic" value="kinetic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>e) A car driving down the road</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="potential" value="potential" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="kinetic" value="kinetic" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>
<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question379',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        acids: [],
        bases: [],
        neutrals: [],
      },
      options: [
        {salt: '$\\ce{LiBr}$', value: 'LiBr'},
        {salt: '$\\ce{NH4(ClO4)}$', value: 'NH4ClO4'},
        {salt: '$\\ce{Na2S}$', value: 'Na2S'},
        {salt: '$\\ce{K2CO3}$', value: 'K2CO3'},
        {salt: '$\\ce{KBr}$', value: 'KBr'},
        {salt: '$\\ce{CH3COONa}$', value: 'CH3COONa'},
      ],
    };
  },
};
</script>
